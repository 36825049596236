/**
 * Industries page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import Container, { ColorContainer, Column, Row } from '../components/Container';
import { Page } from '../components/Page';
import { Body, HeaderH2 } from '../components/Typography';
import { IndexLayout } from '../layouts';

const WrappingRow = styled(Row)({
	flex: 1,
	flexWrap: 'wrap',
	'& :not(:last-child)': {
		marginRight: 32,
	},
});

type Props = {
	data: { markdownRemark: { html: string } };
};

const ImpressumPage: React.FC<Props> = props => {
	const {
		markdownRemark: { html },
	} = props.data;

	const { t } = useTranslation('translations');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer backgroundColor="#fbfbfb">
					<Container>
						<WrappingRow>
							<Column>
								<HeaderH2>{t('imprint.title')}</HeaderH2>
							</Column>
							<Column>
								<Body>
									<b>{t('imprint.company.title')}</b>
									<br />
									ThingOS GmbH & Co KG
									<br />
									Naststr. 15b
									<br />
									70376 Stuttgart
									<br />
									{t('imprint.company.phone')}
									<br />
									{t('imprint.company.mail')}
									<br />
									{t('imprint.company.website')}
									<br />
									{t('imprint.company.court')}
									<br />
									{t('imprint.company.registerNumber')}
									<br />
									{t('imprint.company.taxNumber')}
									<br />
									<br />
									<b>{t('imprint.shareholder.title')}</b>
									<br />
									ThingOS Verwaltungs-GmbH
									<br />
									{t('imprint.shareholder.court')}
									<br />
									{t('imprint.shareholder.registerNumber')}
								</Body>
							</Column>
							<Column>
								<Body>
									<b>{t('imprint.operator.title')}</b>
									<br />
									ThingOS GmbH & Co KG
									<br />
									Naststr. 15b
									<br />
									70376 Stuttgart
									<br />
									{t('imprint.company.phone')}
									<br />
									{t('imprint.company.mail')}
									<br />
									{t('imprint.company.website')}
									<br />
								</Body>
							</Column>
							<Column>
								<Body>
									<b>{t('imprint.director.title')}</b>
									<br />
									Sibylle Thierer
									<br />
									Jörg Schmid
									<br />
									Thomas Kubitza
								</Body>
							</Column>
						</WrappingRow>
					</Container>
				</ColorContainer>
				<ColorContainer>
					<Container>
						<WrappingRow>
							<Column flex>
								<HeaderH2>{t('imprint.privacy.title')}</HeaderH2>
								<Body>
									<div dangerouslySetInnerHTML={{ __html: html }} />
								</Body>
							</Column>
						</WrappingRow>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const pageQuery = graphql`
	query ($language: String!) {
		markdownRemark(frontmatter: { path: { eq: "impressum" } }) {
			html
			frontmatter {
				title
			}
		}
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

// export const query = graphql`
// 	query ($language: String!) {
// 		locales: allLocale(filter: { language: { eq: $language } }) {
// 			edges {
// 				node {
// 					ns
// 					data
// 					language
// 				}
// 			}
// 		}
// 	}
// `;

export default ImpressumPage;
